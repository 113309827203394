export default {
  menu:{ // 登录
    home: '首页',
    foundApply: '开户申请',
    account: '账户管理',
    accountList: '账户列表',
    accountRecord: '开户记录',
    accountRecharge: '智能充值',
    accountMonitor: '账户监控',
    accountMonitorEr: '账户监控②',
    check: '对账管理',
    checkWallet: '钱包充值记录',
    checkBalance: '钱包明细',
    checkCreditBalance: '信用余额明细',
    helpCenter: '帮助中心',
    marketingTool: '红人营销工具',
    crossBorderBrowser: '跨境浏览器',
    storeOperationTools: 'TK店铺运营工具',
    AdvertisementManagement:'广告管理',
    TikTok:'TikTok',

  }, crumb:{
    home: '首页',
    foundApply: '开户申请',
    Account: '账户管理',
    AccountList: '账户列表',
    AccountRecord: '开户记录',
    AccountRecharge: '智能充值',
    AccountMonitor: '账户监控',
    Check: '对账管理',
    CheckWallet: '钱包充值记录',
    CheckBalance: '钱包明细',
    CheckCredit: '信用余额明细',
  }, header:{
    Settings: '帐号设置',
    LogOut: '退出'
  }, home:{ // 登录
    AssetOverview: '资产概览',
    wallet: '钱包余额',
    AdvertisingAccount: '广告账户余额',
    PlatformWallet: '平台钱包',
    creditBalance: '信用余额',
    RechargeWallet: '充值钱包',
    ViewDetails: '查看明细',
    ActiveAccountBalance: '活跃账户余额',
    FrozenBalance: '被冻结余额',
    PlatformGifts: '平台赠金',
    UsedCredit: '已使用授信',
    CreditLimit: '授信额度',
    AccountOpeningApplication: '开户申请',
    inReview: '审核中',
    ToBeModified: '待修改',
    GoAndModifyIt: '去修改',
    DisableRate:'消耗封户率',
    AccountDisableRate:'账户封户率',
    PassedInThePast30Days: '近30天已通过',
    ApplyForAccountOpening: '申请开户',
    PopularEntrance: '热门入口',
    AccountOpeningApplication2: '开户申请',
    IntelligentRecharge: '智能充值',
    AccountReconciliationManagement: '对账管理',
    account: '账户管理',
    WalletRecharge: '钱包充值',
    RechargeAmount: '充值金额',
    EnterAmount: '请输入充值金额',
    PaymentMethod: '支付方式',
    Cancel:'取消',
    ConfirmRecharge:'确认充值',
    Message1:"您充值到平台账户的美金余额，",
    Message2:"不包含已经充值到媒体平台的金额",
    Message3:"充值到媒体平台广告账户的总余额",
    Message4:"平台授予的信用额度余额，",
    Message5:"计算方式为：总授信额度-已使用额度"
  }, login:{ // 登录
    title: '登录',
    NoAccount: '没有账号？',
    register: '去注册',
    WelcomeToLogin: '欢迎登录',
    PleaseEnterYourPhoneNumberOrEmailAddress: '请输入手机号或邮箱',
    PleaseEnterYourLoginPassword: '请输入登录密码',
    ForgotPassword: '忘记密码？',
    VerificationCodeLogin: '验证码登录',
    logOn: '登录',
    LoginSuccess:'成功登录,正在跳转。。。'
  }, register:{ // 注册
    title: '注册',
    ExistingAccount: '已有账号？',
    GoLogIn: '去登陆',
    WelcomeToRegister: '欢迎注册',
    PleaseEnterYourPhoneNumber: '请输入手机号（作为登录的首选账号）',
    LoginPassword:'注意:密码需包含大小写字母及数字（字符数量不限）',
    InvitationCode:'邀请码',
    PlaInvitationCode:'注意:有邀请码请输入,无不需要输入',
    ObtainVerificationCode: '获取验证码',
    PleaseEnterYourFrequentlyUsedEmail: '请输入您常用邮箱（作为备选账号，此项非必填）',
    PleaseEnterA6_20DigitLoginPassword: '请输入6-20位登录密码（需包含大小写字母和数字）',
    FreeRegistration: '免费注册',
    IHaveReadAndAmWillingToAccept: '我已阅读并愿意接受',
    TermsOfService: '《服务条款》',
    and: '和',
    PrivacyStatement: '《隐私声明》',
    ElMPleaseEnterYourPhoneNumber: '请输入手机号码',
    ElMPleaseEnterYourEmailNumber: '请输入邮箱号码',
    ElMPleaseEnterTheVerificationCode: '请输入验证码',
    ElMPleaseEnterPassword: '请输入密码',
    PleaseAgreeToTheServiceAgreement: '请同意服务协议',
    SuccessfullySentSMSVerificationCode: '成功发送短信验证码',
    SuccessfullySentEmailVerificationCode: '成功发送邮件验证码',
    UserAgreement: '《用户协议》',
    UserAgreementAdvertisingComplianceManagementSystem: '《广告合规管理制度》',
    ServiceAndInformationCollectionAgreement: '《服务及信息收集协议》'
  },forget:{// 找回密码
    title: '找回密码',
    NoAccount: '没有账号？',
    register: '去注册',
    RetrievePassword: '找回密码',
    PleaseEnterYourPhoneNumber: '请输入手机号码',
    PleaseEnterTheMobileVerificationCode: '请输入手机验证码',
    SuccessfullySentSMSVerificationCode: '成功发送短信验证码',
    ObtainVerificationCode: '获取验证码',
    ReturnToLogin: '返回登录',
    VerifyWithEmail: '用邮箱验证',
    PleaseEnterYourEmailNumber: '请输入邮箱号码',
    PleaseEnterTheEmailVerificationCode: '请输入邮箱验证码',
    SuccessfullySentEmailVerificationCode: '成功发送邮件验证码',
    VerifyWithMobilePhone: '用手机验证',
    Verify: '验证',
    NewPassword:'请输入新密码',
    AgainNewPassword:'请再次输入新密码',
    NewPasswordLogin:'修改成功，请重新登录',
  },loginSMS:{// 验证码登录
    title: '验证码登录',
    NoAccount: '没有账号？',
    register: '去注册',
    WelcomeToLogin: '欢迎登录',
    PleaseEnterYourPhoneNumber: '请输入手机号',
    PleaseEnterTheMobileVerificationCode: '请输入手机验证码',
    SuccessfullySentSMSVerificationCode: '成功发送短信验证码', 
    ObtainVerificationCode: '获取验证码',   
    PleaseEnterYourEmailAddress: '请输入邮箱',
    PleaseEnterTheEmailVerificationCode: '请输入邮箱验证码',
    SuccessfullySentEmailVerificationCode: '成功发送邮件验证码',
    PasswordLogin: '密码登录',
    EmailVerificationCodeLogin: '邮箱验证码登录',
    MobileVerificationLogin: '手机验证登录',
    logOn: '登录'
  },password:{ // 修改密码
    title: '修改密码',
    ChangePassword: '修改密码',
    PleaseEnterANewPassword: '请输入6-20位新密码（需包含大小写字母和数字）',
    PleaseEnterANewPassword2: '请输入新密码',
    PleaseEnterTheNewPasswordAgain: '请再次输入新密码',
    ConfirmModifications: '确认修改',
    ReturnToLogin: '返回登录',
    ModificationSuccessful: '修改成功，请重新登录'
  },foundApply:{ // 开户申请
    OfficialAuthorizedServiceProvider: '授权服务商',
    TikTok: '风靡全球短视频社交平台，海量新潮年轻用户，通过强导流强曝光的短视频内容带来更高转化率。',
    Google: '全球最大的搜索引擎公司，拥有近30亿月活用户，在客户搜索您的产品或服务的那一刻，恰当其时展示广告。',
    Facebook: '按点击或每千流量付费，运行速度非常快，而且非常易用。另外，广告主还可以通过报表更快的了解不同广告的运行状况。',
    ApplyNow: '立即申请',
    ComingSoon: '即将开放'
  },foundTikTok:{ // 开户申请TikTok
    Tip: "营业执照要求:",
    Tip1:"1.请尽量提供清晰且无水印的大陆地区营业执照证件，且必须是真实最新的版本",
    Tip2:"2.确保营业执照下方的二维码可识别",
    Tip3:"3.提交的营业执照或组织机构代码证照片不清晰或不完整会导致不匹配，核对无误后再提交开户申请",
    BusinessLicense: '营业执照,无水印版本',
    BusinessLicenseTip: '请上传营业执照附件',
    LicenseTip: '营业执照是指证明您的公司合法性的凭证。请上传PNG、JPG、JPEG 文件',
    CompanyRegistrationLocation: '公司注册地',
    CompanyRegistrationLocationTip: '请选择公司注册地',
    CompanyName: '公司名称',
    CompanyNameTip: '请输入企业名称',
    BusinessLicenseNumber: '社会信用代码',
    BusinessLicenseNumberTip: '请输入社会信用代码',
    AccountTimeZone: '账号时区',
    AccountTimeZoneTip: '请选择账号时区',
    PromotionLink: '推广链接',
    PromotionLinkTip: '请输入推广链接',
    PromotionLinkTip2: '请输入正确链接格式',
    ContactEmail: '联系人邮箱',
    ContactEmailTip: '请输入联系人邮箱',
    ContactEmailTip2: '请输入正确邮箱格式',
    BusinessCenterID: '商务中心ID',
    BusinessCenterIDTip: '请输入商务中心ID',
    Reset: '重置',
    Submit: '提交',
  },AccountList:{
    Account: '帐号名称',
    Budget: '预算金额',
    budgetCost: '已消耗金额',
    RequestID: '账户ID',
    RequestIDTip: '请输入账户ID',
    AccountTip: '请输入帐号名称',
    AccountEntity: '开户主体',
    Media: '媒体平台',
    status: '状态',
    AccountID: '帐号名称/账户ID',
    Currency: '币种',
    Balance: '余额',
    DailyBudget: '每日预算',
    AccountRecharge: '账户充值',
    MinRechargePrice: '最低充值金额',
    DeductionTransfer: '减款转账',
    BindUnbind: '绑定/解绑',
    AccountRenaming: '账户更名',
    Fail: '未通过',
    Passed: '已通过',
    InReview: '审核中',
    Operate: '操作',
    Reset: '重置',
    Search: '搜索',
    BatchRecharge: '批量充值',
    BatchAdd: '批量添加',
    BatchOpen: '批量开启',
    ConfirmOpen: '确认开启',
    ConfirmClose: '确认关闭',
    BatchZeroing: '批量清零',
    BatchClose: '批量关闭',
    BatchBinding: '批量绑定/解绑',
    ViewBC: '查看BC',
    NewName: '新名称',
    NewNameTip: '请输入账户新名称',
    Cancel: '取消',
    Confirm: '提交',
    ConfirmModification: '确认修改',
    RechargeAmount: '充值金额',
    RechargeAmountTip: '请输入充值金额',
    BudgetAmount: '预算金额',
    CircuitTime: '巡回时间',
    CircuitTimeTip: '请输入巡回时间',
    BudgetAmountTip: '请输入预算金额',
    DailyBudgetTip: '请输入每日预算',
    ConfirmRecharge: '确认充值',
    ConfirmClear: '确认清空',
    AddAccount: '添加账户',
    BCID: 'BCID',
    EnterBCID: '请输入BC ID',
    SelectBCID: '请选择BC ID',
    Email: '邮箱',
    EnterEmail: '请输入邮箱',
    EnterEmail2: '请输入正确邮箱',
    SelectEmail: '请选择邮箱',
    Permission: '权限',
    SelectPermission: '请选择权限',
    AccountSearch: '请输入帐号名称搜索添加',
    Select: '选择',
    Transfer: '账户减款转账申请',
    AccountBalance: '账户余额',
    EstimatedAeductibleAmount: '预估可减款金额',
    TransferID: '转出账户名称/ID',
    Type: '类型',
    TransferAccount: '转账',
    TransferAmount: '减款',
    TransferMoney: '转出金额',
    Num: '剩余次数',
    EnterTransferAmount: '请输入转出金额',
    IsOut: '是否全部转出',
    Clear: '清零',
    TransferredAccount: '转入账户',
    SelectTransferAccount: '请选择转入账户',
    BindBC: '绑定BC',
    UnbindBC: '解绑BC',
    BindEmail: '绑定邮箱',
    UnbindEmail: '解绑邮箱',
    EnableMonitoring: '开启监控',
  },AccountRecord:{
    Media: '媒体平台',
    Status: '状态',
    AccountEntity: '开户主体',
    ApplyNumber: '申请ID',
    PromotionLink: '推广链接',
    StartTime: '开始时间',
    EndTime: '结束时间',
    Reset: '重置',
    Search: '搜索',
    Export: '导出',
    AccountApplicationID: '公司名称/申请ID',
    CreatedTime: '申请时间',
    Operate: '操作',
    AccountInfo: '账户信息',
    AccountID: '账户id',
    AccountName: '账户名称',
    CompanyName: '企业名称',
    Currency: '币种',
    AccountBalance: '账户余额',
    View: '查看',
    ViewAccount: '查看账户',
    Edit: '修改',
    Copy: '复制',
  },AccountRecharge:{
    Add:'添加账户',
    MediaAccount:'媒体账号',
    AccountPlacement:'投放账号',
    SeriesName:'广告系列名称',
    SeriesNamePrompt:'填写广告系列名称',
    SeriesStatus:'广告系列状态',
    BudgetStatus:'推广系列预算优化',
    GroupName:'广告组名称',
    PlGroupName:'填写广告组名称',
    GroupStatus:'广告组状态',
    DeliveryMethod:'创意投放方式',
    AccountName:'帐号名称',
    MediaPlatform:'媒体平台',
    MonitoringAmount:'监控金额',
    RechargeAmount:'充值金额',
    CircuitTime:'巡回时间',
    State:'状态',
    Modify:'修改',
    Delete:'删除',
    Operate:'操作',
    IsDelete:'是否确认删除',
    SelectMediaPlatform:'请选择媒体平台',
    TransferAccount:'转入账户',
    SelectTransferAccount:'请选择转入账户',
    MonitoringBalance:'监控余额',
    PutMonitoringBalance:'请输入监控账户余额',
    PutRechargeAmount:'请输入充值金额',
    MaximumTwoDecimal:'最大两位小数',
    IntervalTime:'间隔时间',
    PutIntervalTime:'请输入多少小时轮巡',
    Hours:'小时',
    Cancellation:'取消',
    Submit:'提交',
    PleaseEnterInteger:'请输入整数',
    SuccessfullyDeleted:'成功删除'
  },AccountDetails:{ //开户详情
    DocumentInformation:'单据信息',
    ApplicationID:'申请ID',
    MediaPlatform:'媒体平台',
    CreationTime:'创建时间',
    AccountInformation:'账户信息',
    AccountType:'账户类型',
    Industry:'行业',
    DeliveryCountry:'投放国家',
    AccountTimeZone:'账户时区',
    AccountAuthorization:'账户授权',
    PromotionLink:'推广链接',
    Authorization:'授权',
    Number:'序号',
    EmailID:'授权邮箱/商务中心id',
    Permission:'权限',
    EnterpriseInformation:'企业信息',
    CompanyName:'开户公司名称（中文）',
    LicenseCode:'营业执照社会信用代码',
    License:'营业执照附件',
  },CheckWallet:{
    WalletRechargeOrder:'钱包充值订单',
    AdvertisingTransactionOrders:'广告交易订单',
    AdvertisingConsumptionDetails:'广告消耗明细',
    WalletRecharge:'钱包充值',
    RechargeAmount:'充值金额',
    EnterRechargeAmount:'请输入充值金额',
    PaymentMethod:'支付方式',
    SelectPaymentMethod:'请选择支付方式',
    Cancellation:'取消',
    ConfirmRecharge:'确认充值',
    AdvertiserIdPlaceholder:"请输入广告账户ID",
    AdvertiserName:"请输入广告名称",
    Reset:"重置",
    Search:"搜索",
    StaticA:"统计数据:总花费：",
    StaticB:",总广告展现次数：",
    StatTimeDay:"日期",
    AdvertiserId:"广告账号名称/广告账户ID",
    Spend:"总消耗金额",
    Impressions:"广告展现次数",
    Balance:"账户余额",
    advertiserStatus:"账户状态",
  },Advertisement:{ //广告管理
    advertisement:'广告',
     adgroup:'广告组',
    campaign:'广告系列',
    add:'新建广告',

  },CheckBalance:{ // 钱包明细
    OrderNumber:'订单号',
    ChangeType:'变更类型',
    OperationType:'操作类型',
    Reset:'重置',
    Search:'搜索',
    PreChangeAmount:'变更前金额',
    ChangeAmount:'变更金额',
    createdAt:'创建时间',
    AmountAfterChange:'变更后金额',
    Remarks:'备注',
  },CheckCredit:{ // 钱包明细
    OrderNumber:'订单号',
    ChangeType:'变更类型',
    OperationType:'操作类型',
    Reset:'重置',
    Search:'搜索',
    PreChangeAmount:'变更前金额',
    createdAt:'创建时间',
    ChangeAmount:'变更金额',
    AmountAfterChange:'变更后金额',
    Remarks:'备注',
  },CheckAD:{ // 广告交易订单
    AccountType: '账号类型',
    OrderNumber:'订单号',
    AdvertisingName:'广告名称',
    OperationType:'操作类型',
    Status:'状态',
    TransferStatus:'转账状态',
    Reset:'重置',
    Search:'搜索',
    PlatformType: '平台类型',
    Account: '金额',
    TransferPlatform:'转账广告账户平台',
    TransferPlatformId:'转账广告账户名称/转账广告账户Id',
    AccountId:'广告账户名称/广告账户Id',
    Remarks:'备注',
    CreatedAt:'交易时间',
  },CheckPay:{ // 钱包充值订单
    Sn: '订单号',
    Status:'订单状态',
    Reset:'重置',
    Search:'搜索',
    CurrencyName: '币种',
    PayTypeName: '支付方式',
    Amount: '充值金额',
    ExpiresAt:'订单过期时间',
    PayTime:'支付时间',
    Operate:'操作',
    ImmediatePayment:'立即支付',
  },Settings:{
    Account:"帐号信息",
    Company:"公司信息",
    EmailBind:"邮箱绑定",
    PasswordUpdate:"密码修改",
    Avatar:"头像",
    Nickname:"用户昵称",
    NicknamePlaceholder:"请输入用户昵称",
    NicknameRule:"用户昵称长度只支持(3~20)",
    SubmitForm:'提交',
    Upload:"图片不能超过10M",
    UpdateSuccess:"修改成功",
    EnterpriseName:"企业名称",
    EnterpriseEmail:"企业邮箱",
    EnterprisePhone:"联系人电话",
    EnterpriseAddress:"企业地址",
    EnterpriseNamePlaceholder:"请输入企业名称",
    EnterpriseEmailPlaceholder:"请输入企业邮箱",
    EnterprisePhonePlaceholder:"请输入联系人电话",
    EnterpriseAddressPlaceholder:"请输入企业地址",
    Email:"邮箱",
    OldEmailCode:"邮箱验证码",
    OldEmailCodePlaceholder:"请输入绑定邮箱验证码",
    SendSms :"发送验证码",
    BindEmail:"绑定邮箱",
    BindEmailPlaceholder:"请输入绑定邮箱",
    BindEmailCode:"邮箱验证码",
    BindEmailCodePlaceholder:"请输入邮箱验证码",
    SendCodeSuccess:"成功发送验证码",
    EmailBindSuccess:"邮箱绑定成功",
    EmailBindCodePlaceholder:"请输入邮箱验证码",
    EmailSuccess:"请输入正确邮箱",
    OldPassword:"老密码",
    OldPasswordPlaceholder:"请输入老密码",
    Password:"新密码",
    PasswordPlaceholder:"请输入新密码",
    RePassword:"确认密码",
    RePasswordPlaceholder:"请输入确认密码",
    PasswordUpdateSuccess:"密码修改成功",
  }
}